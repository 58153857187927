:root {
  --primary-color: #000000;
  --secondary-color: #e6a825;
  --accent-color: #f49b4d;
  --background-color: #f8f8ff;
  --background-sec-color:#eaf0ed;
  --text-color: #000000;
  --background-gray:#282828;
  --background-light:#dbdbdb;

  --header-height: 80px; 
  --footer-height: 60px;
  --router-height: 100vh; /* Will be updated by JavaScript */
}

@media (max-width: 768px) {
  :root {
    --header-height: 60px;
    --footer-height: 40px;
    --router-height: 100vh; /* Updated by JS */
  }
}


/* Global CSS */
/* Set the background color of the scrollbar track */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the scrollbar track to transparent */
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

html {
  scroll-behavior: smooth;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: var(--router-height);
  margin-top: var(--header-height); /* Sets the top margin to the height of the header */
  overflow-y: auto;
}


.router-content {
  overflow-y: auto;
  flex: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
